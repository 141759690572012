












































































































































































import { FormCreateSession, newSession } from '@/views/dashboard/trainingSession/store/logisticSessionForm'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { formatISO, getHours, getMinutes } from 'date-fns'
import { getXDaysAfter } from '@/plugins/format-date'
import { userConnectedCanAccess } from '@/composables/UserGrant'
import { useDate } from '@/composables/date'
import { trainingLocationRencontresCode, trainingLocationTypeSubRegion } from '@/config-constantes'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
import { TrainingType } from '@/api/interfaces/trainingtype'

export default defineComponent({
  setup (props, ctx) {
    const canEditLogisticSession = () => userConnectedCanAccess('LogisticSession Edit')

    return {
      canEditLogisticSession,
    }
  },
  name: 'CreateLogisticSession',
  components: {
    SelectSearchTrainingType,
    DateField: () => import('@/components/base/DateField.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
    SelectTrainingLocation: () => import('@/components/molecules/select-search/location.vue'),
    SelectTimeSlot: () => import('@/components/molecules/select/timeSlot.vue'),
  },
  computed: {
    isLogisticSessionOnMultipleDays (): boolean {
      let show = false
      this.numberSessionToCreate = 1

      if (
        this.session.trainingType &&
        this.session.trainingType.durationDays !== undefined &&
        this.session.trainingType.durationDays > 1
      ) {
        show = true
        this.numberSessionToCreate = this.session.trainingType.durationDays
      }
      return show
    },
    dateMin (): string {
      const dateMin = new Date(this.session.startAt as string)
      dateMin.setDate(dateMin.getDate() + 1)
      return formatISO(dateMin)
    },
    filterByTrainingLocationType (): string|undefined {
      return this.session?.trainingType?.code === trainingLocationRencontresCode ? trainingLocationTypeSubRegion : undefined
    },
  },
  data () {
    return {
      isSubmitting: false,
      session: newSession() as FormCreateSession,
      redirectToLogisticSessionForm: false,
      numberSessionToCreate: 1,
      timeSlot: null,
    }
  },
  watch: {
    'session.startAt' () {
      if (this.isLogisticSessionOnMultipleDays) {
        const startAtDate = new Date(this.session.startAt as string)
        const endAtDate = new Date(this.session.endAt as string)
        const newEndAtDate = getXDaysAfter(startAtDate, endAtDate, ((this.session.trainingType as TrainingType).durationDays as number) - 1)
        this.session.endAt = formatISO(newEndAtDate)
      } else {
        const end = new Date(this.session.endAt as string)
        this.session.endAt = useDate().createFormatISO(this.session.startAt as string, getHours(end), getMinutes(end))
      }
    },
    'session.endAt' () {
      if (this.isLogisticSessionOnMultipleDays) {
        const startAtDate = new Date(this.session.startAt as string)
        const endAtDate = new Date(this.session.endAt as string)
        const newStartAtDate = getXDaysAfter(endAtDate, startAtDate, -((this.session.trainingType as TrainingType).durationDays as number) + 1)
        this.session.startAt = formatISO(newStartAtDate)
      }
    },
    'session.trainingType' () {
      if (!this.isLogisticSessionOnMultipleDays) {
        this.session.endAt = (this.session.startAt as string).substring(0, 10) + (this.session.endAt as string).substring(10)
      } else if ((this.session.startAt as string).substring(0, 10) === (this.session.endAt as string).substring(0, 10)) {
        const endAtDate = new Date(this.session.endAt as string)
        endAtDate.setDate(endAtDate.getDate() + ((this.session?.trainingType?.durationDays as number) - 1))
        this.session.endAt = formatISO(endAtDate)
      }
    },
    'session.trainingLocation' (newVal) {
      if (!newVal) {
        return
      }

      if (
        !this.session.goalNumberTrainees || this.session.goalNumberTrainees > newVal.maximumCapacity
      ) {
        this.session.goalNumberTrainees = newVal.maximumCapacity
      }
    },
  },
  created () {
    const today = new Date()
    this.session.startAt = formatISO(today)
    this.session.endAt = formatISO(today)
  },
  methods: {
    ...mapActions('logisticSessionForm', {
      createSession: 'createSession',
    }),
    ...mapActions('trainingSessionList', {
      loadTrainingSessions: 'load',
    }),
    async onSubmit () {
      try {
        this.isSubmitting = true
        this.session.numberSessionToCreate = this.numberSessionToCreate
        const logisticSession = await this.createSession(this.session)
        this.$emit('close')
        this.clear()

        if (this.redirectToLogisticSessionForm) {
          this.$router.push({
            name: 'LogisticSession Edit',
            params: { idLogisticSession: logisticSession.id.toString() },
          })
        } else {
          this.loadTrainingSessions()
        }
      } finally {
        this.isSubmitting = false
      }
    },
    clear () {
      this.session = newSession()
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
    },
    changeTrainingSessionHours (startAt: string, endAt: string) {
      const start = new Date('1970-01-01 ' + startAt)
      const end = new Date('1970-01-01 ' + endAt)
      this.session.startAt = useDate().createFormatISO(this.session.startAt as string, getHours(start), getMinutes(start))
      this.session.endAt = useDate().createFormatISO(this.session.endAt as string, getHours(end), getMinutes(end))
    },
  },
})
